// "C:\Users\Andreas\Nextcloud\faledia.all.project\Lernbausteine\SWV\1 Hintergrund\3 Darstellungswechsel\Gruppen_Aktivität 1_10er-System\Vorlage_Lernbaustein_Gruppen_Darstellungswechsel_10erSystem_200917.pptx"
function createObject() {
    let example = {
        id: 'ODWH_2_ODWH3_V2',
        showGroupNames: true,
        checkAfterHowManyItems: 3,
        checkAfterExactItemCount: false,
        workOrder: `
            <sup>1</sup>&frasl;<sub>3</sub> · <sup>3</sup>&frasl;<sub>4</sub> = <sup>1</sup>&frasl;<sub>4</sub> oder anders ausgedrückt <sup>1</sup>&frasl;<sub>3</sub> <i>von</i> <sup>3</sup>&frasl;<sub>4</sub><br />

            Welche Darstellungen passen, welche nicht?! Ziehen Sie die Darstellungen in die jeweilige Gruppe.
            `,
        possibleCategories: [{
                id: 1,
                name: 'Passt'
            },
            {
                id: 2,
                name: 'Passt nicht'
            },
        ],
        elements: [{
                id: 1,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_01.png'),
                category: 1,
                flex: 4
            },
            {
                id: 2,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_02.png'),
                category: 2,
                flex: 4
            },
            {
                id: 3,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_03.png'),
                category: 2,
                flex: 4
            },
            {
                id: 4,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_04.png'),
                category: 2,
                flex: 4
            },
            {
                id: 5,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_05.png'),
                category: 1,
                flex: 4
            },
            {
                id: 6,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_06.png'),
                category: 1,
                flex: 4
            },
            {
                id: 7,
                image: require('@/assets/ove/ODWH_2_ODWH3_V2/odwh3_v2_element_07.png'),
                category: 2,
                flex: 4
            },
        ]
    }

    const var4passtNicht = `Vier der Darstellungen passen nicht zu der Multiplikationsaufgabe. `;

    const var3passtNicht = `Drei der Darstellungen passen nicht zur Multiplikationsaufgabe. `;

    const varErneutPasstNicht = `Schauen Sie noch einmal, welche der Darstellungen sich als nicht förderlich für den Vorstellungsaufbau erweisen. `;

    const var2passtNicht = `Zwei der Darstellungen passen nicht zur Multiplikationsaufgabe. `;

    const var1passtNicht = `Eine der Darstellungen passt nicht zur Multiplikationsaufgabe. `;

    const var3passt = `Drei der Darstellungen sind bereits richtig - sie zeigen sowohl Multiplikator und Multiplikand als auch das Produkt. `;

    const var2passt = `Zwei der Darstellungen sind bereits richtig - sie zeigen sowohl Multiplikator und Multiplikand als auch das Produkt. `;

    const var1passt = `Eine Darstellung ist richtig - sie zeigt sowohl Multiplikator und Multiplikand als auch das Produkt. `;

    const varErneut = `Achten Sie darauf, dass sowohl Multiplikator, Multiplikand und Produkt ersichtlich werden und die Darstellung den Vorstellungsaufbau unterstützt. `;


    example.responses = [{
            id: '04',
            category: example.possibleCategories[1], // Array Index not id
            text: "Genau, diese vier Darstellungen passen nicht zur Multiplikationsaufgabe. Teilweise werden nur Teile der Gleichung fokussiert, beispielsweise nur Multiplikator und Multiplikand, wodurch das Produkt nicht verdeutlicht wird. Das ist für einen Vorstellungsaufbau nicht förderlich."
        },
        {
            id: '03',
            text: var3passtNicht + varErneutPasstNicht
        },
        {
            id: '12',
            text: var1passt + var2passtNicht + varErneut
        },
        {
            id: '13',
            text: var1passt + var3passtNicht + varErneut
        },
        {
            id: '14',
            text: var1passt + var4passtNicht + varErneutPasstNicht
        },
        {
            id: '21',
            text: var2passt + var1passtNicht + varErneut
        },
        {
            id: '22',
            text: var2passt + var2passtNicht + varErneut
        },
        {
            id: '23',
            text: var2passt + var3passtNicht + varErneut
        },
        {
            id: '24',
            text: var2passt + var4passtNicht + varErneutPasstNicht
        },
        {
            id: '30',
            category: example.possibleCategories[0],
            text: "Super, diese drei Darstellungen sind die Richtigen. Hier werden sowohl Multiplikator und Multiplikand als auch das Produkt verdeutlicht und es wird ein Vorstellungsaufbau unterstützt."
        },
        {
            id: '31',
            text: var3passt + var1passtNicht + varErneut
        },
        {
            id: '32',
            text: var3passt + var2passtNicht + varErneut
        },
        {
            id: '33',
            text: var3passt + var3passtNicht + varErneut
        },
        {
            id: '34',
            text: "Leider ist noch keine der Darstellungen richtig zugeordnet. Achten Sie darauf, dass sowohl Multiplikator, Multiplikand und Produkt ersichtlich werden und die Darstellung den Vorstellungsaufbau unterstützt."
        },
    ];

    return example;
}

export default createObject();