<template>
  <div>
    <h2 id="hintergrund">Hintergrundwissen</h2>
    <p>
      Neben dem Zahlverständnis sollen die Lernenden in der Schuleingangsphase
      <strong>ein tragfähiges Verständnis von Rechenoperationen</strong>
      aufbauen. Die Notwendigkeit dieser Zielsetzung ist aus mindestens zwei
      Gründen gegeben. Erstens hat sich gezeigt, dass ein nicht-tragfähiges
      Operationsverständnis häufig bei denjenigen Lernenden zu beobachten ist,
      die Schwierigkeiten in Mathematik haben (Schipper, 2005, S. 53). Anders
      herum: Mit Hilfe eines tragfähigen Operationsverständnisses können Kinder
      leichter lernen, Rechenaufgaben richtig zu bearbeiten und sich deren
      Resultate oder Wege zur Ergebnisermittlung dauerhafter zu merken
      (Gaidoschik, 2007, S. 69 f.). Und zweitens wird ein tragfähiges
      Operationsverständnis immer wieder im weiterführenden Mathematikunterricht
      benötigt: Ein Kind, das über keine Vorstellungen von 3 · 5 verfügt, wird
      mit hoher Wahrscheinlichkeit auch keine Vorstellungen von 1,5 · 2,5 oder
      von <sup>3</sup>&frasl;<sub>2</sub> · <sup>5</sup>&frasl;<sub>2</sub> oder
      allgemein von a · b entwickeln können (Selter & Zannetin, 2018, S. 55).
    </p>

    <p>
      Dem Operationsverständnis liegen drei wesentliche Aspekte zugrunde. Diese
      gelten weithin als Grundlage zur Ausbildung eines umfassenden
      Operationsverständnisses. Daher wird im Folgenden auf diese drei Bereiche eingegangen:
      <ol>
        <li><i>Grundvorstellungen besitzen</i></li>
        <li><i>Darstellungen vernetzen</i></li>
        <li><i>Aufgabenbeziehungen nutzen</i></li>
      </ol>
    </p>

    <h3>Grundvorstellungen besitzen</h3>
    <h4>Was solltest Du zu Grundvorstellungen wissen?</h4>
    <p>
      Zur Entwicklung eines tragfähigen Operationsverständnisses müssen
      <strong>Grundvorstellungen zu Rechenoperationen</strong> aufgebaut werden.
      Unter Grundvorstellungen kann man hier die für das Mathematiklernen
      wichtigen Bedeutungszuschreibungen verstehen, die dazu beitragen, dass die
      Kinder sich unter einer Rechenoperation konkrete Aktivitäten vorstellen
      können und das Rechnen nicht auf das Manipulieren mit Symbolen reduzieren.
    </p>
    <p>
      Natürlich sollen die Schülerinnen und Schüler nicht lernen, die
      unterschiedlichen Grundvorstellungen explizit zu benennen. Aber Du als
      Lehrpersonen solltest diese Unterschiede kennen, um Aufgaben einzusetzen,
      die die unterschiedlichen Grundvorstellungen repräsentieren und auch, um
      Schwierigkeiten zu erkennen, die Lernende haben können.
    </p>
    <p>
      In der Addition und der Subtraktion lassen sich jeweils drei
      Grundvorstellungen unterscheiden, wie die folgende Tabelle zeigt:
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2">Addition</td>
          </tr>
          <tr>
            <td><i>Hinzufügen</i></td>
            <td>
              Zu einer Anzahl von Objekten werden weitere Objekte
              hinzugefügt.<br />
              Beispiel: Sandra hat 3 Gummibärchen. Sie nimmt sich noch 5 Weitere
              aus der Tüte, wie viele hat sie jetzt?
            </td>
          </tr>
          <tr>
            <td><i>Zusammenfassen</i></td>
            <td>
              Zwei Mengen werden zusammengelegt.<br />
              Beispiel: Sandra hat 5 Gummibärchen. Güney hat ebenfalls 5
              Gummibärchen. Wie viele Gummibärchen haben sie zusammen?
            </td>
          </tr>
          <tr>
            <td><i>Vergleichen</i></td>
            <td>
              Zwei Mengen werden verglichen, indem ihre Differenz additiv
              ermittelt wird.<br />
              Beispiel: Sandra hat 8 Gummibärchen, aber Güney hat 2 mehr. Wie
              viele Gummibärchen hat Güney?
            </td>
          </tr>

          <tr>
            <td colspan="2" style="text-align: center">Subtraktion</td>
          </tr>

          <tr>
            <td><i>Abziehen</i></td>
            <td>
              Eine Anzahl von Objekten wird einer Menge weggenommen. Es bleibt
              ein Rest bestehen.<br />
              Beispiel: Julia hat 5 Kekse, sie isst 2. Wie viele Kekse hat sie
              noch über?
            </td>
          </tr>
          <tr>
            <td><i>Ergänzen</i></td>
            <td>
              Ein Unterschied wird dynamisch bestimmt.<br />
              Beispiel: Elias möchte einen Ball kaufen. Der Ball kostet 15€, er
              hat aber nur 10€ dabei. Wie viel Euro muss er aus der Spardose
              nehmen, damit er den Ball bezahlen kann?
            </td>
          </tr>
          <tr>
            <td><i>Vergleichen</i></td>
            <td>
              Ein Unterschied wird statisch bestimmt.<br />
              Beispiel: Ute ist 125cm groß. Benjamin ist 133cm groß. Um wie
              viele Zentimeter ist Benjamin größer?
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>
      Verfügt ein Kind beispielsweise bei der Addition nur über die
      Grundvorstellung des dynamischen Hinzufügens, so könnte es durchaus
      Schwierigkeiten bei Aufgaben des eher statischen Vergleichens bekommen.
      Dadurch, dass die Lernenden Sachaufgaben bzw. Rechengeschichten mit
      vielfältigen Sachkontexten kennenlernen und – anders herum –
      Rechenaufgaben Sachsituationen zuordnen, kann dem entgegengewirkt werden
      (Häsel-Weide & Nührenbörger, 2012, S. 29).
    </p>
    <p>
      Nachdem Du nun einen Überblick über die Grundvorstellungen der Addition
      und Subtraktion erhalten hast, werden dir in der nächsten Aufgabe
      verschiedene multiplikative Situationen präsentiert. Dadurch sollst Du die
      Grundvorstellungen der Multiplikation selbst entdecken. Welche der
      folgenden multiplikativen Situationen lassen sich zu einer
      Grundvorstellung zusammenfassen?
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[0].id"
      :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[0].showGroupNames"
      :workOrder="LBSTGruppen[0].workOrder"
      :possibleCategories="LBSTGruppen[0].possibleCategories"
      :elements="LBSTGruppen[0].elements"
      :responses="LBSTGruppen[0].responses"
    />

    <p>
      Nachdem Du dir die Grundvorstellungen zur Multiplikation nun erarbeitet
      hast, findest Du noch einmal die Grundvorstellungen der Division:
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="2" style="text-align: center">Division</td>
          </tr>
          <tr>
            <td><i>Aufteilen</i></td>
            <td>
              Die Gruppengröße ist bekannt. Die Gruppenanzahl muss bestimmt
              werden. <br />
              Beispiel: In der Klasse sind 24 Kinder. In jede Gruppe sollen 4
              Kinder. Wie viele Gruppen müssen gebildet werden?
            </td>
          </tr>
          <tr>
            <td><i>Verteilen</i></td>
            <td>
              Die Gruppenanzahl ist bekannt. Die Gruppengröße muss bestimmt
              werden.<br />
              Beispiel: In der Klasse sind 24 Kinder. Es sollen 6 Gruppen
              gebildet werden. Wie viele Kinder sind in einer Gruppe?
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>
      Dieses Wissen um Grundvorstellungen im Allgemeinen ermöglicht es nun der
      Lehrkraft, den Kindern Aufgaben zu stellen, die die Kinder mit allen
      möglichen Grundvorstellungen konfrontieren, sodass die Schülerinnen und
      Schüler ein möglichst umfassendes Bild der Operationen aufbauen können.
      Auch wenn die Kinder über das explizite Wissen (wie heißt welche
      Grundvorstellung, wie viele Grundvorstellungen gibt es beispielsweise zur
      Multiplikation, etc.) nicht verfügen müssen, ist es zentral, dass die
      Lehrkraft alle Grundvorstellungen indirekt thematisiert.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            zu (simulierten) Alltagssituationen oder Gegenständen, die den
            verschiedenen Grundvorstellungen entsprechen, passende
            Rechenoperationen nennen, ohne dabei die Grundvorstellung benennen
            zu müssen
          </li>
          <li>Sachsituationen einer Rechenoperation (begründet) zuordnen</li>
          <li>zu einer Sachsituation die passende Rechenaufgabe notieren</li>
        </ul>
      </v-card-text>
    </v-card>

    <br />
    <h3>Darstellungen vernetzen</h3>
    <h4>Was solltest Du zum Darstellungswechsel wissen?</h4>
    <p>
      Neben dem Wissen um die Grundvorstellungen sollten Lehrkräfte aber auch
      Wissen zum Wechsel zwischen den Darstellungsformen haben. Die
      Darstellungsformen lassen sich dabei in vier Kategorien unterscheiden, wie
      die folgende Abbildung zeigt:
    </p>
    <p style="text-align: center">
      <v-img
        contain
        width="400px"
        :src="require('@/assets/ove/ove_darstellungsformen.png')"
        style="margin: 10px auto 0px"
      ></v-img>
      <i style="text-align: center"
        >entnommen aus https://primakom.dzlm.de/node/187</i
      >
    </p>

    <p>
      Das heißt beispielsweise, dass Kinder zu der Aufgabe 3 · 4 eine
      Rechengeschichte erstellen, die im Sinne der gerade erarbeiteten
      Grundvorstellungen sinnvoll ist, oder dass sie ein dazu passendes Bild
      malen können. Die Kinder können dann aber auch beispielsweise aus einer
      geeigneten Handlung eine Rechnung erstellen. Andersherum kann natürlich
      auch jeder Rechenaufgabe eine dazugehörige Alltagssituation oder
      Rechengeschichte zugeschrieben werden, dass die zugrundeliegende Operation
      durch den Kontext repräsentiert wird. Das sollte sowohl zum
      Vorstellungsaufbau genutzt werden, ist aber auch eine eigene zu erlernende
      Kompetenz. Auch nach der Automatisierung des sogenannten kleinen
      Einspluseins oder Einmaleins sollten die Lernenden in der Lage sein, die
      Darstellungen flexibel zu wechseln.
    </p>

    <div style="display: flex">
      <div style="float: left">
        Für die unterschiedlichen Darstellungen gilt: Sie sind einerseits
        Lernhilfe, da sie es den Schülerinnen und Schülern erleichtern
        allgemeine, universellere mathematische Begriffe zu erwerben. Sie sind
        andererseits aber stets auch Lernstoff. Denn es gibt keine simple
        Eins-zu-eins-Zuordnung zwischen Darstellungsmittel und mathematischem
        Begriff, keine Einbahnstraße vom ‚Konkreten‘ zum ‚Abstrakten‘: Der
        abstrakte mathematische Begriff muss durch einen geistigen Akt in das
        Darstellungsmittel hineingelesen werden. Dessen Bedeutung sowie die
        Formen des Umgangs mit ihm müssen von den Schülerinnen und Schülern erst
        erlernt werden.
      </div>
      <v-img
        contain
        width="300px"
        :src="require('@/assets/ove/ove_4plus3.png')"
        style="float: right"
      ></v-img>
    </div>
    <br />

    <p>
      Es hat sich gezeigt, dass Kinder beim Darstellungswechsel auf
      unterschiedliche Kriterien achten (Kuhnke, 2012). Im Folgenden wird dies
      anhand der Beispielaufgabe, passende bildliche Darstellungen zu der
      vorgegebenen Aufgabe 3 · 4 auszuwählen, verdeutlicht.
    </p>

    <br />
    <p>
      Das scheint auf den ersten Blick noch recht simpel, doch die Komplexität
      nimmt in der nachfolgenden Aktivität sukzessive zu. Das dient vor allem
      dazu, um sich in die Kinder hineinversetzen zu können. Der
      Vorstellungsaufbau – der mit der Fähigkeit zum Darstellungswechsel
      einhergeht – für eine neue oder ungewohnte, wenig genutzte und geschulte
      Grundvorstellung erscheint dadurch weniger trivial.
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[1].id"
      :checkAfterHowManyItems="LBSTGruppen[1].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[1].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[1].showGroupNames"
      :workOrder="LBSTGruppen[1].workOrder"
      :possibleCategories="LBSTGruppen[1].possibleCategories"
      :elements="LBSTGruppen[1].elements"
      :responses="LBSTGruppen[1].responses"
    />

    <p>
      In der Multiplikation zweier natürlicher Zahlen fällt es häufig leichter
      eine Einteilung vorzunehmen. Schließlich haben die meisten eine
      Vorstellung zur Multiplikation dazu schon aufgebaut. Den Kindern fällt
      dies häufig noch schwerer, so werden beispielsweise nur Teile der Aufgabe
      – wie Multiplikator und Multiplikand oder das Ergebnis – fokussiert.
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[2].id"
      :checkAfterHowManyItems="LBSTGruppen[2].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[2].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[2].showGroupNames"
      :workOrder="LBSTGruppen[2].workOrder"
      :possibleCategories="LBSTGruppen[2].possibleCategories"
      :elements="LBSTGruppen[2].elements"
      :responses="LBSTGruppen[2].responses"
    />

    <p>
      Die Schwierigkeit ist durch einen Bruch sicherlich bereits erhöht und
      erfordert weitere Überlegungen. Noch komplexer wird die Multiplikation
      zweier Brüche.
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[3].id"
      :checkAfterHowManyItems="LBSTGruppen[3].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[3].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[3].showGroupNames"
      :workOrder="LBSTGruppen[3].workOrder"
      :possibleCategories="LBSTGruppen[3].possibleCategories"
      :elements="LBSTGruppen[3].elements"
      :responses="LBSTGruppen[3].responses"
    />

    <!-- Lassen wir erstmal weg laut Lara und Meike -->
    <!-- <p>
      Die aktivierten Vorstellungsbilder sind über die bearbeiteten Aufgaben
      hinweg deutlich komplexer geworden. Dass bei der Division durch einen
      Bruch mit dem Kehrwert malgenommen werden muss, haben viele abgespeichert.
      Wie kann dazu aber der Darstellungswechsel vollzogen werden?
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[4].id"
      :checkAfterHowManyItems="LBSTGruppen[4].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[4].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[4].showGroupNames"
      :workOrder="LBSTGruppen[4].workOrder"
      :possibleCategories="LBSTGruppen[4].possibleCategories"
      :elements="LBSTGruppen[4].elements"
      :responses="LBSTGruppen[4].responses"
    /> -->

    <p>
      Diese Aufgaben sind dann deutlich komplexer. Für die Kinder, die gerade
      erst den Wechsel zwischen den Darstellungen kennenlernen, ist dies
      ebenfalls nicht trivial. Um ein umfangreiches Operationsverständnis
      aufzubauen, sind Darstellungswechsel aber hilfreich.
    </p>

    <br />
    <p>
      Erstaunlich und nicht zu vernachlässigen ist die Tatsache, dass viele
      Kinder bereits einzelne Malaufgaben kennen, bevor die Multiplikation im
      Unterricht eingeführt wurde. Daher ist es nicht verwunderlich, dass einige
      Kinder beim Darstellungswechsel von symbolischen und bildlichen
      Darstellungen auf das Gesamtergebnis achten. Dementsprechend ‚passen‘
      Darstellungen für sie zusammen, wenn beide dasselbe Ergebnis haben.
      Beispielsweise werden alle Darstellungen mit 12 Elementen – unabhängig von
      deren Anordnung – der Aufgabe 3 · 4 zugeordnet.
    </p>
    <p>
      Kinder fokussieren besonders auch einzelne Elemente, die in beiden
      Darstellungen vorkommen müssen. Sie nehmen zum Beispiel die 4 in den Blick
      und wählen weitere Darstellungen aus, bei welchen mehrmals 4 zu sehen
      sind.
    </p>
    <p>
      Kinder fokussieren sich auf die Relation der Elemente. Darstellungen
      ‚passen‘ immer dann zusammen, wenn in beiden dieselbe Relation zu finden
      ist. Für das Beispiel 3 · 4 heißt das: Alle Darstellungen in welchen genau
      drei Vierer zu finden sind, auch unabhängig von der Anordnung innerhalb
      der Darstellung, werden als passend klassifiziert.
    </p>
    <p>
      Gerade bei schwächeren Kindern zeigt sich, dass Handlungen am konkreten
      Material weniger eine Hürde darstellen. Die Umstellung aber vom Konkreten
      weg, hin zum gedanklichen Handeln, scheint den Kindern schwer zu fallen
      (vgl. vom Hofe 1995). Daher haben Wartha und Schulz ein vier Phasen Modell
      zur Unterstützung entworfen: 1. Handlung am geeigneten Material, 2.
      Beschreibung der Materialhandlung mit Sicht auf das Material, 3.
      Beschreibung der Materialhandlung ohne Sicht auf das Material, 4. Arbeiten
      auf symbolischer Ebene, Üben und Automatisieren (vgl. Wartha & Schulz
      2011). Um folglich zu versuchen möglichst vielen Kindern den
      Darstellungswechsel zu erleichtern, erscheint eine Heranführung nach den
      oben genannten Schritten sinnvoll.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            Darstellungsmitteln die intendierte(n) Aufgabe(n) zuordnen
            (Lernstoff)
          </li>
          <li>
            Darstellungsmittel zur Lösung einer Aufgabe nutzen (Lernhilfe)
          </li>
          <li>
            die Darstellung einer Aufgabe flexibel von einer Darstellung in eine
            andere übertragen
          </li>
          <li>
            die Erklärung einer anderen Person zu einem Darstellungswechsel
            wiedergeben
          </li>
          <li>ausgeführte Darstellungswechsel erklären</li>
          <li>
            beim Darstellungswechsel alle Elemente einer Aufgabe berücksichtigen
            und sich nicht nur auf einen Einzelaspekt beschränken
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />

    <h3>Aufgabenbeziehungen nutzen</h3>
    <h4>Was solltest Du zum Nutzen von Aufgabenbeziehungen wissen?</h4>
    <p>
      Als dritte Komponente ist die Nutzung von Beziehungen – einerseits
      zwischen Aufgaben, andererseits aber auch zwischen den Rechenoperationen –
      von besonderer Bedeutung für die Entwicklung eines tragfähigen
      Operationsverständnisses. Ohne diese können sich sichere und flexible
      Kompetenzen im Rechnen nicht entwickeln.
    </p>
    <p>
      Zum Rechnen allgemein und insbesondere zum Erlernen des schnellen
      Kopfrechnens ist es wichtig, Beziehungen zwischen Aufgaben herzustellen
      bzw. zu sehen, um die Ergebnisse bereits beherrschter Aufgaben zur
      Berechnung anderer Aufgaben zu nutzen. Es ist natürlich am Ende des
      Lernprozesses eine Automatisierung des kleinen Einmaleins ein Ziel. Das
      sollte aber nicht zu früh angebahnt werden, um dem beziehungsreichen
      Lernen und Abteilen von Aufgaben den nötigen Stellenwert zuschreiben zu
      können. Dabei spielen Rechengesetze der elementaren Arithmetik eine
      besondere Rolle. Die Lernenden können diese verwenden, ohne die Gesetze in
      der allgemeinen Formulierung kennen zu müssen – letzteres soll auch gar
      nicht Ziel des Mathematikunterrichts der Grundschule sein.
    </p>
    <p>
      Um sich genauer damit zu beschäftigen, wie Kinder Aufgaben nutzen und
      welche Rechengesetze und Zusammenhänge dahinterstecken, machst Du die
      folgende Aktivität. Dazu hat Mia bei einigen Aufgaben erklärt, wie sie
      vorgeht.
    </p>

    <LernbausteinStempeln
      :id="LBSTStempeln[0].id"
      :workOrder="LBSTStempeln[0].workOrder"
      :mediaDescription="LBSTStempeln[0].mediaDescription"
      :image="LBSTStempeln[0].image"
      :audio="LBSTStempeln[0].audio"
      :elements="LBSTStempeln[0].elements"
    />

    <LernbausteinStempeln
      :id="LBSTStempeln[1].id"
      :workOrder="LBSTStempeln[1].workOrder"
      :mediaDescription="LBSTStempeln[1].mediaDescription"
      :image="LBSTStempeln[1].image"
      :audio="LBSTStempeln[1].audio"
      :elements="LBSTStempeln[1].elements"
    />

    <LernbausteinStempeln
      :id="LBSTStempeln[2].id"
      :workOrder="LBSTStempeln[2].workOrder"
      :mediaDescription="LBSTStempeln[2].mediaDescription"
      :image="LBSTStempeln[2].image"
      :audio="LBSTStempeln[2].audio"
      :elements="LBSTStempeln[2].elements"
    />

    <LernbausteinStempeln
      :id="LBSTStempeln[3].id"
      :workOrder="LBSTStempeln[3].workOrder"
      :mediaDescription="LBSTStempeln[3].mediaDescription"
      :image="LBSTStempeln[3].image"
      :audio="LBSTStempeln[3].audio"
      :elements="LBSTStempeln[3].elements"
    />

    <LernbausteinStempeln
      :id="LBSTStempeln[4].id"
      :workOrder="LBSTStempeln[4].workOrder"
      :mediaDescription="LBSTStempeln[4].mediaDescription"
      :image="LBSTStempeln[4].image"
      :audio="LBSTStempeln[4].audio"
      :elements="LBSTStempeln[4].elements"
    />

    <p>
      Zur Übersicht dient die folgende Tabelle, die die Gesetze und
      Zusammenhänge, die Du gerade in der Aktivität schon angewendet hast, noch
      einmal übersichtlich darstellt.
    </p>

    <v-row>
      <v-col cols=gesetzeTabelleFormat>
    <!-- Kommutativgesetze-Tabelle -->
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2"><strong>Kommutativgesetz</strong></td>
          </tr>
          <tr>
            <td>
              <strong>Addition:</strong> Die Summanden einer Additionsaufgabe
              können vertauscht werden, ohne dass sich dabei das Ergebnis
              ändert.
            </td>
            <td class="no-wrap">
              <v-row justify="center">8 + 3 = 3 + 8</v-row>
              <v-row justify="center">a + b = b + a</v-row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Multiplikation:</strong> Die Faktoren einer
              Multiplikationsaufgabe können vertauscht werden, ohne dass sich
              dabei das Ergebnis ändert.
            </td>
            <td class="no-wrap">
              <v-row justify="center">7 · 4 = 4 · 7</v-row>
              <v-row justify="center">a · b = b · a</v-row>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <i
                >Das <b>Kommutativgesetz</b> gilt nicht für die Subtraktion (8 –
                4 ist ungleich 4 – 8) und auch nicht für die Division (6 : 3 ist
                ungleich 3 : 6)</i
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
      </v-col>

    <v-col cols=gesetzeTabelleFormat>
    <!-- Assoziativgesetz-Tabelle-->
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2"><strong>Assoziativgesetz</strong></td>
          </tr>
          <tr>
            <td>
              <strong>Addition:</strong> Die Summanden einer (mehrgliedrigen)
              Additionsaufgabe können beliebig miteinander verbunden werden.
            </td>
            <td class="no-wrap">
              <v-row justify="center">(2 + 3) + 1 = 2 +(3 + 1)</v-row>
              <v-row justify="center">(a + b) + c = a + (b + c)</v-row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Multiplikation:</strong> Die Faktoren einer
              (mehrgliedrigen) Multiplikationsaufgabe können beliebig
              miteinander verbunden werden.
            </td>
            <td class="no-wrap">
              <v-row justify="center">(6 · 5) · 7 = 6 · (5 · 7)</v-row>
              <v-row justify="center">(a · b) · c = a · (b · c)</v-row>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <i
                >Das <b>Assoziativgesetz</b> gilt nicht für die Subtraktion,
                denn (8 – 4) – 3 = 4 – 3 = 1 ist ungleich 8 – (4 – 3) = 8 – 1 =
                7 und auch nicht für die Division: (36 : 6) : 3 = 6 : 3 = 2 ist
                ungleich 36 : (6 : 3) = 36 : 2 = 18.</i
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-col>
    </v-row>

    <v-row>
      <v-col cols=gesetzeTabelleFormat>
    <!-- Distributivgesetz-Tabelle-->
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2"><strong>Distributivgesetz</strong></td>
          </tr>
          <tr>
            <td cols=8>
              <strong>Addition/ Multiplikation bzw. Division:</strong>
              Eine Summe wird mit einem Faktor multipliziert (bzw. durch einen
              Divisor dividiert), indem jeder Summand mit dem Faktor
              multipliziert (bzw. durch den Divisor dividiert) wird und die
              Resultate addiert werden.
            </td>
            <td class="no-wrap">
              <v-row justify="center">6 · 4 = 2 · 4 + 4 · 4</v-row>
              <v-row justify="center">(a + b) · c = a · c + b · c</v-row>
              <br />
              <v-row justify="center">72 ː 8 = 56 ː 8 + 16 ː 8</v-row>
              <v-row justify="center">(a + b) ː c = a ː c + b ː c</v-row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Subtraktion/ Multiplikation bzw. Division:</strong>
              Eine Differenz wird mit einem Faktor multipli-ziert (bzw. durch
              einen Divisor dividiert), indem Minuend und Subtrahend mit dem
              Faktor multipliziert (bzw. durch den Divisor dividiert) werden und
              die Resultate voneinander sub-trahiert werden.
            </td>
            <td class="no-wrap">
              <v-row justify="center">7 · 4 = 10 · 4 – 3 · 4</v-row>
              <v-row justify="center">(a – b) · c = a · c – b · c</v-row>
              <br />
              <v-row justify="center">186 ː 6 = 198 ː 6 – 12 ː 6</v-row>
              <v-row justify="center">(a- b) ː c = a ː c – b ː c</v-row>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <i
                >Analog gilt das <b>Distributivgesetz</b> bei der Multiplikation
                auch, wenn der zweite Faktor „zerlegt“ wird. Die Zerlegung des
                Divisors funktioniert aber in der Regel nicht.</i
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
      </v-col>

    <v-col cols=gesetzeTabelleFormat>
    <!-- Konstanzgesetz-Tabelle-->
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr style="text-align: center">
            <td colspan="2"><strong>Konstanzgesetz</strong></td>
          </tr>
          <tr>
            <td>
              <strong>Addition:</strong> Das Ergebnis ändert sich nicht, wenn
              man eine Zahl vergrößert und die andere entsprechend verkleinert.
            </td>
            <td class="no-wrap">
              <v-row justify="center">3 + 5 = 4 + 4</v-row>
              <v-row justify="center">a + b = (a + n) + (b – n)</v-row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Subtraktion:</strong> Das Ergebnis ändert sich nicht, wenn
              man beide Zahlen um dieselbe Zahl vergrößert (oder verkleinert).
            </td>
            <td class="no-wrap">
              <v-row justify="center">13 – 8 = 14 – 9</v-row>
              <v-row justify="center">a – b = (a + n) – (b + n)</v-row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Multiplikation:</strong> Das Ergebnis ändert sich nicht,
              wenn man eine Zahl multipliziert und die andere entsprechend
              dividiert.
            </td>
            <td class="no-wrap">
              <v-row justify="center">
              18 · 16 = 36 · 8
              </v-row>
              <v-row justify="center">
                a · b = (a · n) · (b ː n)
              </v-row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Division:</strong> Das Ergebnis ändert sich nicht, wenn
              man beide Zahlen durch dieselbe Zahl dividiert (oder entsprechend
              multipliziert).
            </td>
            <td class="no-wrap">
              <v-row justify="center">48 ː 6 = 24 ː 3</v-row>
              <v-row justify="center">a ː b = (a ː n) ː (b ː n)</v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-col>
    </v-row>
    <br />

    <p>
      Bedeutsam sind des Weiteren die Zusammenhänge zwischen den
      Rechenoperationen. Die Multiplikation beispielsweise kann man als
      wiederholte Addition gleicher Summanden verstehen (4 · 7 = 7 + 7 + 7 + 7).
      Sie bildet gleichzeitig die Umkehroperation der Division, was die Lösung
      von Divisionsaufgaben mit Hilfe der Multiplikation zu einer häufig zu
      beobachtenden Lösungsstrategie macht (36 : 4 = 9, weil 9 · 4 = 36). Auch
      das sind Zusammenhänge die Kinder durchaus erkennen und nutzen können.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>Beziehungen zwischen Aufgaben und Rechenoperationen benennen</li>
          <li>
            die Erklärung einer anderen Person zu Beziehungen zwischen Aufgaben
            und Rechenoperationen wiedergeben
          </li>
          <li>Beziehungen zwischen Aufgaben und Rechenoperationen erklären</li>
          <li>
            mithilfe von Darstellungen in verschiedenen Darstellungsformen
            Beziehungen zwischen Aufgaben und Rechenoperationen darstellen (z.B.
            mit Plättchen, Forschermitteln o.ä.)
          </li>
          <li>
            Zusammenhänge zwischen Aufgaben und Rechenoperationen zum flexiblen
            Rechnen nutzen
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />

    <v-row justify="end">
      <v-btn text color="primary" @click="navigateToDiagnose">
        Weiter mit: Diagnose
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-row>

    <br/>
    <br/>
    <br/>
    <h4 id="literaturverzeichnis">Literaturverzeichnis</h4>
      <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              Gaidoschik, M. (2007). <i>Rechenschwäche vorbeugen – Erstes Schuljahr: Vom Zählen zum Rechnen.</i> Wien: ÖBV HPT.
            </td>
          </tr>
          <tr>
            <td>
              Häsel-Weide, U., & Nührenbörger, M. (2012). <i>Fördern im Mathematikunterricht.</i> In H. Bartnitzky, U. Hecker & M. Lassek (Hrsg.), <i>Individuell fördern – Kompetenzen stärken – in der Eingangsstufe.</i> Frankfurt am Main: Grundschulverband.
            </td>
          </tr>
          <tr>
            <td>
              Kuhnke, K. (2012). <i>Vorgehensweisen von Grundschulkindern beim Darstellungswechsel – Eine Untersuchung am Beispiel der Multiplikation im 2. Schuljahr.</i> Wiesbaden: Springer.
            </td>
          </tr>
          <tr>
            <td>
              Schipper, W. (2005). <i>Lernschwierigkeiten erkennen - verständnisvolles Lernen fördern.</i> http://www.sinus-angrundschulen.de/fileadmin/uploads/Material_aus_STG/Mathe-Module/M4.pdf
            </td>
          </tr>
          <tr>
            <td>
              Selter, C., & Zannetin, E. (2018). <i>Mathematik unterrichten in der Grundschule. Inhalte – Leitideen – Beispiele.</i> Seelze: Kallmeyer.
            </td>
          </tr>
          <tr>
            <td>
              vom Hofe, R. (1995). <i>Grundvorstellungen mathematischer Inhalte.</i> Heidelberg: Spektrum.
            </td>
          </tr>
          <tr>
            <td>
              Wartha, S., & Schulz, A. (2011). <i>Aufbau von Grundvorstellungen (nicht nur) bei besonderen Schwierigkeiten im Rechnen.</i> Kiel: IPN.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_OGVH_Gruppen from "./beispiele/OGVH_Gruppen";
import LernbausteinGruppen_ODWH_2_ODWH1 from "./beispiele/ODWH_2_ODWH1_V2";
import LernbausteinGruppen_ODWH_2_ODWH2 from "./beispiele/ODWH_2_ODWH2_V2";
import LernbausteinGruppen_ODWH_2_ODWH3 from "./beispiele/ODWH_2_ODWH3_V2";
import LernbausteinGruppen_ODWH_2_ODWH4 from "./beispiele/ODWH_2_ODWH4_V2";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinStempeln_OABH2_13plus9 from "./beispiele/OABH2_13+9";
import LernbausteinStempeln_OABH2_19plus21 from "./beispiele/OABH2_19+21";
import LernbausteinStempeln_OABH2_11plus178 from "./beispiele/OABH2_11+178";
import LernbausteinStempeln_OABH2_8mal7 from "./beispiele/OABH2_8mal7";
import LernbausteinStempeln_OABH2_12mal5 from "./beispiele/OABH2_12mal5";

export default {
  beforeCreate: function () {
    AppHelper.isUserAllowedRedirectIfNot("ove");
  },
  components: {
    LernbausteinGruppen,
    LernbausteinStempeln,
  },
  data: () => ({
    LBSTGruppen: [
      LernbausteinGruppen_OGVH_Gruppen,
      LernbausteinGruppen_ODWH_2_ODWH1,
      LernbausteinGruppen_ODWH_2_ODWH2,
      LernbausteinGruppen_ODWH_2_ODWH3,
      LernbausteinGruppen_ODWH_2_ODWH4,
    ],
    LBSTStempeln: [
      LernbausteinStempeln_OABH2_13plus9,
      LernbausteinStempeln_OABH2_19plus21,
      LernbausteinStempeln_OABH2_11plus178,
      LernbausteinStempeln_OABH2_8mal7,
      LernbausteinStempeln_OABH2_12mal5,
    ],
  }),
  methods: {
    navigateToDiagnose: function () {
      this.$router.push({ name: "ove_diagnose" });
    },
  },
  computed: {
    gesetzeTabelleFormat () {
      if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
        return 12;
      } else {
        return 6;
      }
    }
  }
};
</script>

<style scoped>
.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
}
.frac > span {
  display: block;
  padding: 0.1em;
}
.frac span.bottom {
  border-top: thin solid black;
}
.frac span.symbol {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

</style>
