function createObject() {
    let example = {
        id: 'OABH2_19+21',
        workOrder: `
            Links können Sie sich eine Kinderlösung anhören. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangenen Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        mediaDescription: `Mia rechnet die Aufgaben 19 + 21 und 18 + 34. Hören Sie sich an, wie sie vorgeht.`,
        audio: require("@/assets/ove/Stempeln/mia_19plus21.mp4"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie rechnet Mia vermutlich 17+18?",
                    answers: [{
                            correct: true,
                            text: "17+18=15+20",
                            //additionalExplanation: "",
                            response: `Sehr gut, genau so hat Mia die Aufgabe gelöst.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "17+18=17+17+1=34+1",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat die Aufgabe anders gerechnet.`,
                        },
                        {
                            correct: false,
                            text: "17+18=10+7+10+8=10+10+7+8=20+15",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat die Aufgabe anders gerechnet.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Welches Rechengesetz nutzt Mia hier?",
                    answers: [{
                            correct: false,
                            text: "Kommutativgesetz",
                            //additionalExplanation: "",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Kommutativgesetz: a+b = b+a`,
                            //additionalExplanationResponse: "",
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Distributivgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Distributivgesetz: a∙b+a∙c = a∙(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Assoziativgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c).`,
                            cols: 6
                        },
                        {
                            correct: true,
                            text: "Konstanzgesetz",
                            response: `Sehr gut, Mia nutzt hier das Konstanzgesetz der Summe: a+b = (a±1)+(b±1).`,
                            cols: 6
                        },
                    ]
                },
                {
                    question: "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
                    answers: [{
                            correct: false,
                            text: "Der Zehnerübergang kann durch Zerlegung eines Summanden erleichtert werden.",
                            //additionalExplanation: "",
                            response: `Das ist so nicht richtig. Es wird kein Summand zerlegt. Vielmehr werden beide Summanden gegensinnig verändert, um eine leichtere Aufgabe mit demselben Ergebnis zu erhalten.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: true,
                            text: "Das Konstanzgesetz der Summe kann angewendet werden, damit ein Summand ein glatter Zehner wird.",
                            response: `Durch die gegensinnige Veränderung beider Summanden wird eine leichtere Aufgabe erzeugt, da durch einen glatten Zehner der Zehnerübergang umgangen wird.`,
                        },
                        {
                            correct: false,
                            text: "Die Zerlegung in Königsaufgaben erleichtert das Rechnen, indem Teilaufgaben gerechnet werden.",
                            response: `Das ist so nicht richtig. Es werden keine Königaufgaben gebildet. Vielmehr werden beide Summanden gegensinnig verändert, um eine leichtere Aufgabe mit demselben Ergebnis zu erhalten.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();