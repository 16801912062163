function createObject() {
    let example = {
        id: 'OABH2_12mal5',
        workOrder: `
            Links können Sie sich eine Kinderlösung anhören. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangenen Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        mediaDescription: `Mia rechnet die Aufgaben 12 • 5 und 4 • 14. Hören Sie sich an, wie sie vorgeht.`,
        audio: require("@/assets/ove/Stempeln/mia_12mal5.mp4"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie rechnet Mia vermutlich 16⋅4 ?",
                    answers: [{
                            correct: true,
                            text: "16⋅4 = 8⋅8",
                            //additionalExplanation: "",
                            response: `Sehr gut, genau so hat Mia die Aufgabe gelöst.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "15⋅5 = 10⋅5+5⋅5",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat die Aufgabe anders gerechnet.`,
                        },
                        {
                            correct: false,
                            text: "16⋅4 = 16⋅2⋅2 = 32⋅2",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat die Aufgabe anders gerechnet.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Welches Rechengesetz nutzt Mia hier?",
                    answers: [{
                            correct: false,
                            text: "Kommutativgesetz",
                            //additionalExplanation: "",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Kommutativgesetz: a+b = b+a`,
                            //additionalExplanationResponse: "",
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Distributivgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Distributivgesetz: a∙b+a∙c = a∙(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Assoziativgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c).`,
                            cols: 6
                        },
                        {
                            correct: true,
                            text: "Konstanzgesetz",
                            response: `Sehr gut, Mia nutzt hier das Konstanzgesetz der Multiplikation: a·b = (a:2)·(b·2).`,
                            cols: 6
                        },
                    ]
                },
                {
                    question: "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
                    answers: [{
                            correct: false,
                            text: "Immer, wenn ein Zahl halbiert und die andere verdoppelt wird, entsteht eine einfachere Aufgabe.",
                            //additionalExplanation: "",
                            response: `Das stimmt so nicht. Denke die Aufgabe in die andere Richtung, es ist nicht einfacher aus 8⋅8 dann 16⋅4 zu machen.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: true,
                            text: "Das Konstanzgesetz des Produkts kann angewendet werden, damit Aufgaben des kleinen Einmaleins entstehen.",
                            response: `Genau, so wird durch gegensinniges Verändern eine einfachere Aufgabe erzeugt.`,
                        },
                        {
                            correct: false,
                            text: "Die Zerlegung in Königsaufgaben erleichtert das Rechnen, indem Teilaufgaben gerechnet werden.",
                            response: `Das ist so nicht richtig. Hier wird keine Zerlegung vorgenommen, vielmehr werden beide Faktoren gegensinnig verändert, um eine leichtere Aufgabe zu erzeugen.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();