function createObject() {
    let example = {
        id: 'OABH2_13+9',
        workOrder: `
            Links können Sie sich eine Kinderlösung anhören. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangenen Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        audio: require("@/assets/ove/Stempeln/mia_13plus9.mp4"),
        mediaDescription: `Mia rechnet die Aufgaben 13 + 9 und 38 + 7. Hören Sie sich an, wie sie vorgeht.`,
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie rechnet Mia 15+8 vermutlich?",
                    answers: [{
                            correct: true,
                            text: "15+8	= 15+(5+3)	= (15+5)+3	= 20+3	= 23",
                            response: `Super, genauso hat Mia diese Aufgabe gerechnet.`,
                            // additionalExplanationResponse: 'schmier',
                        },
                        {
                            correct: false,
                            text: "15+8	= (3+12)+8	= 3+(12+8)	= 3+20	= 23",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat es anders gemacht.`,
                        },
                        {
                            correct: false,
                            text: "15+8	= (10+5)+8	= 10+(5+8)	= 10+13	= 23",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat es anders gemacht.`,
                        },
                    ],
                },
            ],
            [
                // this is stage 2
                {
                    question: "Welches Rechengesetz nutzt Mia hier?",
                    answers: [{
                            correct: false,
                            text: "Kommutativgesetz",
                            response: `Hören Sie nochmal, wie Mia vorgeht. Zur Erinnerung das Kommutativgesetz: a+b = b+a.`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Distributivgesetz",
                            response: `Hören Sie nochmal, wie Mia vorgeht. Zur Erinnerung das Distributivgesetz: a∙b+a∙c = a∙(b+c).`,
                            cols: 6
                        },
                        {
                            correct: true,
                            text: "Assoziativgesetz",
                            response: `Genau, Mia nutzt das Assoziativgesetz: (a+b)+c= a+(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Konstanzgesetz",
                            response: `Hören Sie nochmal, wie Mia vorgeht. Zur Erinnerung das Kontanzgesetz der Summe: a+b = (a±1)+(b±1).`,
                            cols: 6
                        },
                    ],
                },
                {
                    question: "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
                    answers: [{
                            correct: true,
                            text: "Der Zehnerübergang kann durch Zerlegung eines Summanden erleichtert werden.",
                            response: `Genau. Durch das Zerlegen eines Summanden kann der Zehnerübergang bei einigen Aufgaben erleichtert werden. Dabei ist es irrelevant, welcher Summand zerlegt wird.`,
                        },
                        {
                            correct: false,
                            text: "Dass der erste Summand der größere ist, ermöglicht dieses Vorgehen.",
                            response: `Das ist so nicht richtig. Beim Zerlegen ist es egal, welche Zahl die größere ist – ebenso wie es egal ist, welche zerlegt wird.`,
                        },
                        {
                            correct: false,
                            text: "Die Nähe zum glatten Zehner legt dieses Vorgehen nahe.",
                            response: `Das ist so nicht richtig. Die Zerlegung eines Summanden ist immer möglich (auch sogar ohne Zehnerübergang), sodass es dem Kind dann leichter fallen kann, die Ergebnisse zu addieren.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();