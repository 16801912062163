function createObject() {
    let example = {
        id: 'OABH2_8mal7',
        workOrder: `
            Links können Sie sich eine Kinderlösung anhören. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangenen Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        mediaDescription: `Mia rechnet die Aufgaben 8 • 7 und 8 • 3. Hören Sie sich an, wie sie vorgeht.`,
        audio: require("@/assets/ove/Stempeln/mia_8mal7.mp4"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie rechnet Mia vermutlich 9·6?",
                    answers: [{
                            correct: true,
                            text: "9⋅6=9 ⋅(5+1) = 9⋅5+9⋅1",
                            //additionalExplanation: "",
                            response: `Sehr gut, genau so hat Mia die Aufgabe gelöst.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "9⋅6=9⋅(2⋅3) = (9⋅2)⋅3 =18⋅3",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat die Aufgabe anders gerechnet.`,
                        },
                        {
                            correct: false,
                            text: "9⋅6=(5+4)⋅(5+1)=5⋅5+4⋅1",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat die Aufgabe anders gerechnet.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Welches Rechengesetz nutzt Mia hier?",
                    answers: [{
                            correct: false,
                            text: "Kommutativgesetz",
                            //additionalExplanation: "",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Kommutativgesetz: a+b = b+a`,
                            //additionalExplanationResponse: "",
                            cols: 6
                        },
                        {
                            correct: true,
                            text: "Distributivgesetz",
                            response: `Genau, Mia nutzt hier das Distributivgesetz: a∙b+a∙c = a∙(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Assoziativgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Konstanzgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Konstanzgesetz der Summe: a+b = (a±1)+(b±1).`,
                            cols: 6
                        },
                    ]
                },
                {
                    question: "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
                    answers: [{
                            correct: false,
                            text: "Der Zehnerübergang kann durch Zerlegung einer der Faktoren erleichtert werden.",
                            //additionalExplanation: "",
                            response: `Das ist so nicht richtig. Bei der Multiplikation kann der Zehnerübergang nicht erleichtert werden.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Die Zerlegung des Multiplikators ist immer leichter.",
                            response: `Das ist so nicht richtig. Zum einen muss nicht jedes Mal der Multiplikator zerlegt werden, zum anderen wird nicht jede Aufgabe dadurch automatisch einfacher.`,
                        },
                        {
                            correct: true,
                            text: "Die Zerlegung von Multiplikator oder Multiplikand ermöglicht es, sogenannte Königsaufgaben zu rechnen und sich die Aufgabe so abzuleiten.",
                            response: `Die Zerlegung kann das Berechnen von einfacheren Aufgaben zur Folge haben und ist somit sinnvoll, um nicht nur das kleine Einmaleins auswendig zu lernen, sondern beziehungsreich und somit anschlussfähig zu lernen.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();