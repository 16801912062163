function createObject() {
    let example = {
        id: 'OABH2_11+178',
        workOrder: `
            Links können Sie sich eine Kinderlösung anhören. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangenen Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        mediaDescription: `Mia rechnet die Aufgaben 11 + 178 und 14 + 185. Hören Sie sich an, wie sie vorgeht.`,
        audio: require("@/assets/ove/Stempeln/mia_11plus178.mp4"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Wie rechnet Mia 17+144 vermutlich?",
                    answers: [{
                            correct: true,
                            text: "17+144 = 144+17",
                            //additionalExplanation: "",
                            response: `Sehr gut, genau so hat Mia die Aufgabe gelöst.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "17+144 = 17+100+40+4",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat es anders gemacht.`,
                        },
                        {
                            correct: false,
                            text: "17+144 = 11+150",
                            response: `Hören Sie sich Mias Beschreibung nochmal an. Sie hat es anders gemacht.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "Welches Rechengesetz nutzt Mia hier?",
                    answers: [{
                            correct: true,
                            text: "Kommutativgesetz",
                            //additionalExplanation: "",
                            response: `Sehr gut, Mia nutzt hier das Kommutativgesetz: a+b = b+a`,
                            //additionalExplanationResponse: "",
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Distributivgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Distributivgesetz: a∙b+a∙c = a∙(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Assoziativgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Assoziativgesetz: (a+b)+c= a+(b+c).`,
                            cols: 6
                        },
                        {
                            correct: false,
                            text: "Konstanzgesetz",
                            response: `Hören Sie sich nochmal an, wie Mia vorgeht. Zur Erinnerung das Konstanzgesetz der Summe: a+b = (a±1)+(b±1).`,
                            cols: 6
                        },
                    ]
                },
                {
                    question: "Welche Eigenschaft der Aufgabe führt dazu, dass es sinnvoll ist, das Gesetz anzuwenden?",
                    answers: [{
                            correct: true,
                            text: "Dass der erste Summand der Ausgangsaufgabe relativ klein ist, erleichtert so das Weiterrechnen (vielleicht noch mit zählenden Strategien).",
                            //additionalExplanation: "",
                            response: `Das Tauschen macht vor allem Sinn, da beide unterschiedlich groß sind. Das (Weiter)-Rechnen wird so erleichtert.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Dass beide Summanden so weit auseinander liegen, ermöglicht dieses Vorgehen.",
                            response: `Das ist so nicht richtig. Egal wie weit die beiden Zahlen auseinander liegen, kann es leichter sein, die Aufgaben kommutativ zu vertauschen, um das (Weiter-)Rechnen zu erleichtern.`,
                        },
                        {
                            correct: false,
                            text: "Die Tauschaufgabe ist immer leichter als die Ausgangsaufgabe.",
                            response: `Das ist so nicht richtig. Es kann auch Fälle geben, in denen die Originalaufgabe einfacher ist als die Tauschaufgabe. Das hängt von den Zahlen ab.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();