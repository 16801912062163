// "C:\Users\Andreas\Nextcloud\faledia.all.project\Lernbausteine\OV\Lernbausteine_OGVH_2\Vorlage_Lernbaustein_Grundvorstellungen_Multiplikation_200921.pptx"
function createObject() {
    let example = {
        id: 'OGVH2_Multiplikation_200921',
        showGroupNames: false,
        checkAfterHowManyItems: 4,
        checkAfterExactItemCount: true,
        workOrder: `
            Nachdem Sie nun die Grundvorstellungen der Addition und Subtraktion kennengelernt haben, überlegen Sie welche Grundvorstellungen der Multiplikation die folgenden 16 Aufgaben nahelegen. Es gibt drei verschiedene Grundvorstellungen – zu jeder passen vier der folgenden Aufgaben. Vier weitere Aufgaben legen keine Grundvorstellung nah. 
            Ziehen Sie vier Aufgaben in eine Gruppe, die Ihrer Meinung nach derselben Grundvorstellung entsprechen.
            `,
        possibleCategories: [{
                id: 1,
                name: 'Wiederholen'
            },
            {
                id: 2,
                name: 'Zusammenfassen'
            },
            {
                id: 3,
                name: 'Vergleichen'
            },
            {
                id: 4,
                name: 'keinerlei Grundvorstellung'
            }
        ],
        elements: [{
                id: 1,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_1.png'),
                category: 2,
                flex: 3
            },
            {
                id: 2,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_2.png'),
                category: 1,
                flex: 3
            },
            {
                id: 3,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_3.png'),
                category: 4,
                flex: 3
            },
            {
                id: 4,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_4.png'),
                category: 3,
                flex: 3
            },
            {
                id: 5,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_5.png'),
                category: 3,
                flex: 3
            },
            {
                id: 6,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_6.png'),
                category: 4,
                flex: 3
            },
            {
                id: 7,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_7.png'),
                category: 2,
                flex: 3
            },
            {
                id: 8,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_8.png'),
                category: 2,
                flex: 3
            },
            {
                id: 9,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_9.png'),
                category: 4,
                flex: 3
            },
            {
                id: 10,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_10.png'),
                category: 3,
                flex: 3
            },
            {
                id: 11,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_11.png'),
                category: 1,
                flex: 3
            },
            {
                id: 12,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_12.png'),
                category: 4,
                flex: 3
            },
            {
                id: 13,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_13.png'),
                category: 1,
                flex: 3
            },
            {
                id: 14,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_14.png'),
                category: 2,
                flex: 3
            },
            {
                id: 15,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_15.png'),
                category: 3,
                flex: 3
            },
            {
                id: 16,
                image: require('@/assets/ove/OGVH_Gruppen/OGVH_16.png'),
                category: 1,
                flex: 3
            },
        ]
    }

    const var3wiederholen = `3 der Aufgaben beinhalten eine zeitliche Wiederholung derselben Handlung, `;

    const var1zusammenfassen = `eine fasst Gruppen gleicher Größe zusammen, `;
    const var1zusammenfassenGross = `Eine fasst Gruppen gleicher Größe zusammen, `;

    const var1vergleichen = `eine stellt einen Vergleich zwischen zwei Gruppengrößen an, `;
    const var1vergleichenGross = `Eine stellt einen Vergleich zwischen zwei Gruppengrößen an, `;

    const var1keinerleiGV = `eine Aufgabe legt keinerlei multiplikativen Strukturen zugrunde, `;

    const var1wiederholen = `Eine Aufgabe beinhaltet eine zeitliche Wiederholung derselben Handlung, `;

    const var3zusammenfassen = `3 der Aufgaben fassen Gruppen gleicher Größe zusammen, `;

    const var3vergleichen = `3 der Aufgaben stellen einen Vergleich zwischen zwei Gruppengrößen an, `;

    const var3keinerleiGV = `3 der Aufgaben legen keinerlei multiplikative Struktur nahe, `;

    const var2wiederholen = `2 der Aufgaben beinhalten eine zeitliche Wiederholung derselben Handlung, `;

    const var2zusammenfassen = `2 der Aufgaben fassen Gruppen gleicher Größe zusammen, `;

    const var2vergleichen = `2 der Aufgaben stellen einen Vergleich zwischen zwei Gruppengrößen an, `;

    const varErneutSingular = `welche Aufgabe passt nicht? Versuchen Sie es erneut. `;
    const varErneutPlural = `welche Aufgaben passen nicht? Versuchen Sie es erneut. `;

    const var2keinerleiGV = `2 der Aufgaben legen keinerlei multiplikativen Strukturen zugrunde, `;


    example.responses = [{
            id: '4000',
            category: example.possibleCategories[0], // Array Index not id
            text: "Super. Bei diesen vier Aufgaben ist eine wiederholte Handlung zu erkennen, sodass Gruppen gleicher Größe zusammengefasst werden. Diese Grundvorstellung heißt also Wiederholen oder zeitlich-sukzessiv."
        },
        {
            id: '3100',
            text: var3wiederholen + var1zusammenfassen + varErneutSingular,
        },
        {
            id: '3010',
            text: var3wiederholen + var1vergleichen + varErneutSingular,
        },
        {
            id: '3001',
            text: var3wiederholen + var1keinerleiGV + varErneutSingular,
        },
        {
            id: '2200',
            text: var2wiederholen + var2zusammenfassen + varErneutPlural,
        },
        {
            id: '2110',
            text: var2wiederholen + var1zusammenfassen + var1vergleichen + varErneutPlural,
        },
        {
            id: '2101',
            text: var2wiederholen + var1zusammenfassen + var1keinerleiGV + varErneutPlural,
        },
        {
            id: '2020',
            text: var2wiederholen + var2vergleichen + varErneutPlural,
        },
        {
            id: '2011',
            text: var2wiederholen + var1vergleichen + var1keinerleiGV + varErneutPlural,
        },
        {
            id: '2002',
            text: var2wiederholen + var2keinerleiGV + varErneutPlural,
        },
        {
            id: '1300',
            text: var1wiederholen + var3vergleichen + varErneutSingular,
        },
        {
            id: '1210',
            text: var1wiederholen + var2zusammenfassen + var1vergleichen + varErneutPlural,
        },
        {
            id: '1201',
            text: var1wiederholen + var2zusammenfassen + var1keinerleiGV + varErneutPlural,
        },
        {
            id: '1120',
            text: var1wiederholen + var1zusammenfassen + var2vergleichen + varErneutPlural,
        },
        {
            id: '1111',
            text: "Upps. Das ist noch sehr durcheinander. Welche Aufgaben legen eine zeitliche Wiederholung derselben Handlung nah, welche eher ein Zusammenfassen gleichgroßer Gruppen? Gibt es Aufgaben, die Vergleiche anstellen oder welche, die nicht richtig in den Kontext einer Multiplikation passen? Versuchen Sie es erneut.",
        },
        {
            id: '1102',
            text: var1wiederholen + var1zusammenfassen + var2keinerleiGV + varErneutPlural,
        },
        {
            id: '1030',
            text: var1wiederholen + var3vergleichen + varErneutSingular,
        },
        {
            id: '1021',
            text: var1wiederholen + var2vergleichen + varErneutPlural,
        },
        {
            id: '1012',
            text: var1wiederholen + var1vergleichen + var2keinerleiGV + varErneutPlural,
        },
        {
            id: '1003',
            text: var1wiederholen + var3keinerleiGV + varErneutSingular,
        },
        {
            id: '0400',
            category: example.possibleCategories[1],
            text: "Sehr gut. Hier werden Anzahlen gleicher Größe gruppiert und die Gesamtzahl ermittelt. Diese Grundvorstellung heißt Zusammenfassen oder räumlich simultan.",
        },
        {
            id: '0310',
            text: var3zusammenfassen + var1vergleichen + varErneutSingular,
        },
        {
            id: '0301',
            text: var3zusammenfassen + var1keinerleiGV + varErneutSingular,
        },
        {
            id: '0220',
            text: var2zusammenfassen + var2vergleichen + varErneutPlural,
        },
        {
            id: '0211',
            text: var2zusammenfassen + var1vergleichen + var1keinerleiGV + varErneutPlural,
        },
        {
            id: '0202',
            text: var2zusammenfassen + var2keinerleiGV + varErneutPlural,
        },
        {
            id: '0130',
            text: var1zusammenfassenGross + var3vergleichen + varErneutSingular,
        },
        {
            id: '0121',
            text: var1zusammenfassenGross + var2vergleichen + var1keinerleiGV + varErneutPlural,
        },
        {
            id: '0112',
            text: var1zusammenfassenGross + var1vergleichen + var2keinerleiGV + varErneutPlural,
        },
        {
            id: '0103',
            text: var1zusammenfassenGross + var3keinerleiGV + varErneutSingular,
        },
        {
            id: '0040',
            category: example.possibleCategories[2],
            text: "Prima. Beim sogenannten Vergleichen werden zwischen Anzahlen oder Größen multiplikative Vergleiche hergestellt.",
        },
        {
            id: '0031',
            text: var3vergleichen + var1keinerleiGV + varErneutSingular,
        },
        {
            id: '0022',
            text: var2vergleichen + var2keinerleiGV + varErneutPlural,
        },
        {
            id: '0013',
            text: var1vergleichenGross + var3keinerleiGV + varErneutSingular,
        },
        {
            id: '0004',
            category: example.possibleCategories[3],
            text: "Richtig. Diese vier Aufgaben legen keinerlei multiplikative Grundvorstellung nah.",
        },
    ];

    return example;
}

export default createObject();